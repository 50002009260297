import React from 'react'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import { createRoot } from 'react-dom/client'

import { mountQuickSession } from '@/offline/components/schedule-offline-session/helper'
import { mountPostSessionReact } from '@/ps/helper'

import { Sync } from './apps/sync/Sync'

import '@/core/prelude'
import 'what-input'
import '../css/styles.css'
import './nonce'

window.Stimulus = Application.start()
const context = require.context('./controllers', true, /\.js$/)
window.Stimulus.load(definitionsFromContext(context))

// mount Sync dropdown in the Header
const syncRootElement = document.getElementById('sync-root')
if (syncRootElement) {
  createRoot(syncRootElement).render(<Sync />)
}

// mount Post Session page React app
mountPostSessionReact()

//mount Quick Session
mountQuickSession()
