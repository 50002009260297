import { mountStoreDevtool } from 'simple-zustand-devtools'
import { createRoot } from 'react-dom/client'
import React, { lazy, Suspense } from 'react'

import {
  getPostSessionStore,
  usePostSessionStoreBase,
} from '@/ps/stores/post_session_store'

import spinner from '../../../images/spinner.gif'

const PostSessionRoot = lazy(() => import('./index'))

export const mountPostSessionReact = () => {
  const post_session_root = document.getElementById('post-session-root')
  const dataScript = document.getElementById('post_session_data')

  if (post_session_root && dataScript) {
    let data
    try {
      data = JSON.parse(dataScript?.textContent || '')
    } catch (ex) {
      throw new Error('failed to parse initial data')
    }
    // Initialize post session store
    getPostSessionStore().initialize(data)

    //react devtools zustand store
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'integration'
    ) {
      mountStoreDevtool('PostSessionStore', usePostSessionStoreBase)
    }

    createRoot(post_session_root).render(
      <Suspense
        fallback={
          <div className="flex justify-center">
            <img className="w-48 h-48" src={spinner} alt="loading..." />
          </div>
        }
      >
        <PostSessionRoot />
      </Suspense>,
    )
  }
}
