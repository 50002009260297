import { TFunction } from 'i18next'

import { SyncStatus } from './types'

export const getSyncStatus = (t: TFunction<['common']>): SyncStatus => {
  return {
    SYNCED: {
      title: t('Your data is synced', { ns: 'common' }),
      icon: 'cloud_done',
    },
    SYNCING: {
      title: t('Currently syncing...', { ns: 'common' }),
      icon: 'cloud_sync',
    },
    NO_CONNECTION: {
      title: t("You're offline", { ns: 'common' }),
      icon: 'cloud_off',
    },
    CONNECTION_TIMEOUT: {
      title: t('Connection timeout', { ns: 'common' }),
      icon: 'cloud_error',
    },
  }
}
